<template>
  <div class="center-wrapper">
    <div class="main-wrapper">
      <h1>What are you feeling like today ?</h1>
      <div>
        <div class="category">
          <div class="category-title">Sports</div>
          <div class="subcategories">
            <router-link
              to="/Articles/Tricking-Trampoline"
              class="subcategory no-style"
              >Tricking/Trampoline</router-link
            >
            <a href="" class="no-style subcategory"
              >Cliff Diving <span class="emoji">🌊</span></a
            >
            <a href="" class="no-style subcategory"
              >Calisthenics <span class="emoji">🤸‍♂️</span></a
            >
          </div>
        </div>
        <div class="category">
          <div class="category-title">Tech</div>
          <div class="subcategories">
            <router-link to="/Articles/Computers" class="subcategory no-style"
              >Computers <span class="emoji">💻</span></router-link
            >

            <a href="" class="no-style subcategory"
              >Phones <span class="emoji">📱</span></a
            >
            <router-link
              to="/Articles/Productivity"
              class="subcategory no-style"
              >Productivity <span class="emoji">⚙</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.category {
  border: solid white 1px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.category-title {
  margin-bottom: 20px;
  font-weight: bold;
}
.subcategories {
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;
  }
}
.subcategory {
  border: solid white 1px;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}
.emoji {
  font-size: 1.4em;
  vertical-align: middle;
}
</style>
